<template>
  <div class="cs-container" style="background: white">
    <div
      class="d-print-none mb-2 flex items-center justify-end"
      style="margin-right: 80px"
    >
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h6 class="text-center col-10 title-color" style="font-weight: 600;">
        PHIẾU ĐỒNG THUẬN THỰC HIỆN XÉT NGHIỆM TẦM SOÁT NGUY CƠ UNG THƯ DI TRUYỀN
      </h6>
      <span class="close-icon d-print-none" @click="backToDetail">&times;</span>
    </div>

    <div id="printOrderForm" class="my-3">
      <b-row>
        <b-col>

          <div>
            <div class="cs-flex"><div class="cs-title">PHẦN 1: THÔNG TIN BỆNH NHÂN</div></div>
            <div class="cs-flex">
              <div class="cs-flex flex-grow-1">
                <span class="cs-label">Họ tên:
                </span>
                <span class="cs-content flex-grow-4" v-html="form.full_name"></span>
                <span class="cs-label">Ngày sinh:
                </span>
                <span class="cs-content flex-grow-2" v-html="formatDMY(form.birthday)"></span>
                <span class="cs-label">Giới:
                </span>
                <span class="cs-content flex-grow-1" v-html="getGender(form.gender)"></span>
              </div>
            </div>
            <div class="cs-flex">
              <div class="cs-flex w-80">
                <span class="cs-label">Địa chỉ:
                </span>
                <span class="cs-content" v-html="form.address"></span>
                <span class="cs-label">SĐT:
                </span>
              </div>
              <span class="cs-content flex-grow-1" v-html="form.phone_number"></span>
            </div>
            <div class="cs-flex">
              <div class="cs-flex w-80">
                <span class="cs-label">Bác sĩ:
                </span>
                <span class="cs-content" v-html="form.doctor_name"></span>
                <span class="cs-label">PK/Bệnh viện:
                </span>
                <span class="cs-content" v-html="form.clinic"></span>
                <span class="cs-label">SĐT:
                </span>
              </div>
              <span class="cs-content flex-grow-1" v-html="form.doctor_phone_number"></span>
            </div>
            <div class="cs-flex">
              <div class="cs-flex w-80">
                <span class="cs-label">Ngày thu mẫu:
                </span>
                <span class="cs-content" v-html="formatDMY(form.sample_taken_at)"></span>
                <span class="cs-label">Mã XN:
                </span>
              </div>
              <span class="cs-content flex-grow-1" v-html="form.sample_id"></span>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 2: XÉT NGHIỆM YÊU CẦU
              </span>
            </div>
            <div class="cs-flex">
              <div class="d-flex flex-column cs-content">
                <div class="mb-1 cs-checkbox">
                  <input :checked="form.test_requirement === 1" id="urgent" name="urgent" type="checkbox" />
                  <label><b>Ung thư vú:</b> BRCA1, BRCA2, PALB2, PTEN, TP53, CDH1, STK11</label>
                </div>
                <div class="mb-1 cs-checkbox">
                  <input :checked="form.test_requirement === 2" id="urgent" name="urgent" type="checkbox" />
                  <label><b>17 gen liên quan đến hơn 11 loại ung thư di truyền:</b> BRCA1, BRCA2, PALB2, PTEN, TP53, CDH1, MLH1, MSH2, MSH6, PMS2, EPCAM, APC, MUTYH, STK11, VHL, RB1, RET</label>
                </div>
                <div class="mb-1 cs-checkbox">
                  <input :checked="form.test_requirement === 3" id="urgent" name="urgent" type="checkbox" style="min-width: 18px" />
                  <label><b>133 gen liên quan đến hơn 20 loại ung thư di truyền:</b> AIP, ALK, ANKRD26, APC, ATM, AXIN2, BAP1, BARD1, BLM, BMPR1A*, BRAF*, BRCA1*, BRCA2, BRIP1, BUB1B, CBL, CDC73, CDH1, CDK4, CDKN1B, CDKN1C, CDKN2A, CEBPA, CEP57, CHEK2*, CYLD, DDB2, DICER1*, DIS3L2*, DKC1, EGFR, ELANE, EPCAM, ERCC1, ERCC2, ERCC3, ERCC4, ERCC5, ETV6, EXO1, EXT1, EXT2, EZH2, FANCA, FANCB, FANCC, FANCD2*, FANCE, FANCF, FANCG, FANCI, FANCL, FANCM, FH, FLCN, GATA2, GCP3, GREMI, HNF1A, HOXB13, HRAS, KIT, KITLG, KRAS*, MAP2K1, MAP2K2, MAX, MEN1, MET, MITF, MLH1, MLH3, MRE11A, MSH2, MSH6, MUTYH, NBN, NF1*, NF2, NRAS, NSD1, NSUN2, NTHL1, PALB2, PAX5, PDGFRA, PHOX2B, PMS1, PMS2*, POLD1, POLH*, PPM1D, PRF1, PRKAR1A, PTCH1, PTEN*, PTPN11, RAD50, RAD51C, RAF1, RECQL4, RET, RHBDF2, RUNX1, SDHA*, SDHAF2, SDHB, SDHC, SDHD, SHOC2, SLX4, SMAD4, SMARCA4, SMARCB1, SOS1, SPRED1, SRP72*, STK11, SUFU, TERC, TERT, TINF2, TMEM127, TP53, TSC1, TSC2, VHL, WRN*, WT1, XPA, XPC, XRCC2</label>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 3: TIỀN SỬ BỆNH UNG THƯ CỦA BỆNH NHÂN
              </span>
            </div>
            <div class="cs-flex">
              <div class="d-flex flex-column cs-content">
                <div class="mb-1 cs-checkbox">
                  Nếu bệnh nhân bị ung thư, xin cung cấp thông tin:
                </div>
                <div class="mb-1 cs-checkbox">
                  <div class="cs-checkbox w-30">
                    <input :checked="form.cancer_history.breastCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                    <label><b>Ung thư vú.</b></label>
                  </div>
                  <div class="cs-flex w-20">
                    <span class="cs-label">Tuổi mắc:
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.breastCancer.ageHave"></span>
                  </div>
                  <div class="cs-flex">
                    <span class="cs-label">Phân loại (nếu biết):
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.breastCancer.category"></span>
                  </div>
                </div>
                <div class="mb-1 cs-checkbox">
                  <div class="cs-checkbox w-30">
                    <input :checked="form.cancer_history.ovaryCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                    <label><b>Ung thư buồng trứng.</b></label>
                  </div>
                  <div class="cs-flex flex-grow-1">
                    <span class="cs-label">Tuổi mắc:
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.ovaryCancer.ageHave"></span>
                  </div>
                </div>
                <div class="mb-1 cs-checkbox">
                  <div class="cs-checkbox w-30">
                    <input :checked="form.cancer_history.intestineCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                    <label><b>Ung thư ruột/ trực tràng.</b></label>
                  </div>
                  <div class="cs-flex w-20">
                    <span class="cs-label">Tuổi mắc:
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.intestineCancer.ageHave"></span>
                  </div>
                  <div class="cs-flex">
                    <span class="cs-label">Phân loại (nếu biết):
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.intestineCancer.category"></span>
                  </div>
                </div>
                <div class="mb-1 cs-checkbox">
                  <div class="cs-checkbox w-30">
                    <input :checked="form.cancer_history.uterusCancer.isHave" id="urgent" name="urgent" type="checkbox" />
                    <label><b>Ung thư nội mạc tử cung.</b></label>
                  </div>
                  <div class="cs-flex flex-grow-1">
                    <span class="cs-label">Tuổi mắc:
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.uterusCancer.ageHave"></span>
                  </div>
                </div>
                <div class="mb-1 cs-checkbox">
                  <div class="cs-checkbox w-30">
                    <input :checked="form.cancer_history.polyp.isHave" id="urgent" name="urgent" type="checkbox" />
                    <label><b>Polyp ở ruột/ trực tràng</b></label>
                  </div>
                  <div class="cs-flex flex-grow-1">
                    <span class="cs-label">Số lượng polyp:
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.polyp.number"></span>
                  </div>
                </div>
                
                <div class="mb-1 cs-checkbox">
                  <div class="cs-flex">
                    <span class="cs-label"><b><u>Kết quả IHC của khối u bất thường:</u></b>
                    </span>
                    <span class="cs-content" v-html="form.cancer_history.ihc_result"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 4: TIỀN SỬ BỆNH UNG THƯ CỦA GIA ĐÌNH</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >Xin liệt kê các thành viên trong gia đình đã được chẩn đoán ung thư (mỗi hàng một thành viên)</span>
            </div>
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex">
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">
                      Mối quan hệ với bệnh nhân
                    </div>
                  </div>
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">
                      Vị trí ung thư
                    </div>
                  </div>
                  <div class="col-4 p-0">
                    <div class="cs-flex">
                      Tuổi mắc
                    </div>
                  </div>
                </div>
                <div v-for="(row, i) in form.family_cancer" :key="i" class="d-flex cs-border-t">
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">
                      {{ row.relationship }}<br v-if="!row.relationship"/>
                    </div>
                  </div>
                  <div class="col-4 p-0 cs-border-r">
                    <div class="cs-flex">
                      {{ row.cancer_position }}<br v-if="!row.cancer_position"/>
                    </div>
                  </div>
                  <div class="col-4 p-0">
                    <div class="cs-flex">
                      {{ row.ageHave }}<br v-if="!row.ageHave"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>

          <div class="html2pdf__page-break" style="margin-top:6rem;">
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 5: ĐỒNG Ý LÀM XÉT NGHIỆM CỦA BỆNH NHÂN</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >Tôi, ký tên dưới đây hiểu rằng:</span>
            </div>
            <ul>
              <li class="col-12">
                Tôi đã được cung cấp thông tin về những lợi ích và hạn chế của các xét nghiệm di truyền mà tôi yêu cầu được thực hiện.
              </li>
              <li class="col-12">
                Tôi đã được cung cấp thông tin về độ tin cậy của các kết quả xét nghiệm, và về mức độ liên quan của kết quả xét nghiệm với các bệnh ung thư cụ thể. Kết quả được dùng như một yếu tố tiên đoán cho việc xuất hiện các bệnh ung thư liên quan trong tương lai.
              </li>
              <li class="col-12">
                Tôi tự nguyện đồng ý làm xét nghiệm tầm soát đột biến của các gen: {{ form.gene_list || '...............' }}
              </li>
              <li class="col-12">
                Tôi là chủ sở hữu kết quả xét nghiệm của tôi. Nhà cung cấp dịch vụ không được thảo luận cũng như tiết lộ kết quả xét nghiệm và bệnh sử y tế liên quan cho bên thứ ba, trừ trường hợp có liên quan đến việc điều trị hoặc thanh toán để điều trị, mà không có sự cho phép bằng văn bản của tôi.
              <div class="cs-width-full cs-flex">
                <div class="container-border cs-width-full">
                  <div class="d-flex">
                    <div class="col-6 p-0 cs-border-r">
                      <div class="cs-flex">
                        Bệnh nhân kí, ghi rõ họ tên:
                        <br/><br/><br/>
                      </div>
                    </div>
                    <div class="col-6 p-0 cs-border-r">
                      <div class="cs-flex">
                        Bác sĩ chỉ định:
                      </div>
                    </div>
                  </div>
                  <div class="d-flex cs-border-t">
                    <div class="col-6 p-0 cs-border-r">
                      <div class="cs-flex">Ngày.......tháng.......năm.......</div>
                    </div>
                    <div class="col-6 p-0 cs-border-r">
                      <div class="cs-flex"><br/></div>
                    </div>
                  </div>
                </div>
              </div>  
              </li>
              <li class="col-12">
                Xét nghiệm di truyền đối với trẻ em dưới 18 tuổi phải có sự đồng ý của cha mẹ hoặc người giám hộ hợp pháp. Nếu là người giám hộ hợp pháp, vui lòng ghi rõ mối quan hệ với bệnh nhân: {{ form.relationship_to_patient || '...............' }}
              </li>
            </ul>
          </div>

          <div>
            <div class="cs-flex">
              <span class="cs-title"
                >PHẦN 6: THÔNG TIN CẦN BIẾT TRƯỚC KHI XÉT NGHIỆM</span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >
                <b><u>Mục tiêu:</u></b> Xét nghiệm này giúp nhận biết các đột biến xảy ra trên các gen được khảo sát; những gen này liên quan đến nguy cơ ung thư di truyền. Vì vậy, xét nghiệm giúp xác định nguy cơ phát triển của một dạng ung thư nhất định dựa trên sự hiện diện của đột biến trên gen được khảo sát. Xét nghiệm gen cho phép ước lượng nguy cơ ung thư chính xác hơn so với việc dựa trên tiền sử bệnh của cá nhân và gia đình.
              </span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >
                <b><u>Hình thức trả kết quả:</u></b> 
                Các đột biến được phát hiện trong xét nghiệm sẽ được chia thành 5 nhóm dựa trên cơ sở dữ liệu của ClinVar (Viện sức khỏe quốc gia Hoa Kỳ):
              </span
              >
            </div>
            <ul>
              <li class="col-12">
                Nhóm đột biến gây bệnh (pathogenic variants) và gần giống gây bệnh (likely pathogenic variants): là nhóm đột biến gia tăng nguy cơ phát triển ung thư di truyền. Bệnh nhân mang những đột biến này nên thăm khám bác sĩ để có kế hoạch chăm sóc sức khỏe và phòng ngừa ung thư phù hợp.
              </li>
              <li class="col-12">
                Nhóm đột biến không rõ chức năng (variants of uncertain significance): là nhóm đột biến mà đến hiện thời chức năng chưa rõ ràng vì chưa đủ chứng cứ khoa học hoặc các bằng chứng mâu thuẫn nhau.  
              </li>
              <li class="col-12">
                Nhóm đột biến lành tính (benign variants) và gần giống lành tính (likely benign variants): là những nhóm đột biến không gia tăng nguy cơ phát triển ung thư di truyền. Nhóm đột biến này sẽ không thông báo trong bảng trả kết quả.
              </li>
            </ul>
            <div class="cs-flex">
              <span class="cs-label"
                >
                <b><u>Lợi ích của xét nghiệm:</u></b> 
                Kết quả xét nghiệm sẽ giúp bệnh nhân và bác sĩ điều trị có kế hoạch phòng ngừa ung thư phù hợp bao gồm: tầm soát ung thư định kì, giải phẫu để giảm thiểu nguy cơ ung thư hoặc điều trị thuốc phòng ngừa. Hơn thế nữa, nếu bệnh nhân mang đột biến gây bệnh, các thành viên khác trong gia đình của bệnh nhận được khuyến cáo thực hiện xét nghiệm này. Nếu một thành viên gia đình không mang đột biến di truyền, người này có thể chắc là không truyền đột biến này cho con của mình.
              </span
              >
            </div>
            <div class="cs-flex">
              <span class="cs-label"
                >
                <b><u>Giới hạn:</u></b> 
                Xét nghiệm chỉ kiểm tra một số gen quan trọng liên quan đến nguy cơ phát triển của một số dạng ung thư di truyền nhất định. Những gen khác ít liên quan đến nguy cơ phát triển ung thư sẽ không được khảo sát trong xét nghiệm này. Nhóm đột biến không rõ chức năng có thể được xem là không cung cấp thông tin về nguy cơ ung thư đối với những người thân của bệnh nhân mang nhóm đột biến này.  
              </span
              >
            </div>
          </div>

          <hr/>
          <div class="cs-flex justify-center">
            <span class="cs-label"
              >
              <b><i>
                (Trả kết quả dự kiến sau 14 ngày làm việc trừ thứ 7, CN, ngày nghỉ, lễ tính từ ngày phòng xét nghiệm nhận mẫu)
              </i></b>
            </span
            >
          </div>

        </b-col>
      </b-row>
    </div>
  </div>
</template>
    
<script>
import { DEFAULT_CIPHERS } from 'tls';
import { mapState } from "vuex";
import HeaderForm from "@/views/PrintOrderForm/HeaderForm.vue"

const DEFAULT_FORM = {
  full_name: "",
  birthday: "",
  gender: null,
  address: "",
  phone_number: "",
  doctor_name: "",
  clinic: "",
  doctor_phone_number: null,
  sample_taken_at: "",
  sample_id: "",
  test_requirement: null,
  cancer_history: {
    breastCancer: {
      isHave: false,
      ageHave: null,
      category: ""
    },
    ovaryCancer: {
      isHave: false,
      ageHave: null,
    },
    intestineCancer: {
      isHave: false,
      ageHave: null,
      category: ""
    },
    uterusCancer: {
      isHave: false,
      ageHave: null,
    },
    polyp: {
      isHave: false,
      number: null,
    },
    ihc_result: ""
  },
  family_cancer: [
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
    {
      relationship: "",
      cancer_position: "",
      ageHave: null
    },
  ]
};

export default {
  name: "PrintConsentCancerForm",
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    formatDMY (date) {
      return date ? window.moment(date).format('DD/MM/YYYY') : ""
    },
    handlePrint() {
      document.title = "Consent-form-for-cancer-screening";
      window.print();
    },
    handleMapData() {
      this.form = this.orders.requisitionFormV2 || { ...DEFAULT_FORM };
    },
    getGender(genderId) {
      return genderId === 2 ? "Nữ" : "Nam"
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders.viewDetail.id,
        },
        query: {
          step: 1,
        },
      });
    },
  },
};
</script>
    
<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
    font-size: 18px;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .w-90 {
    width: 90%;
  }
  .w-80 {
    width: 80%;
  }
  .w-30 {
    width: 30%;
  }
  .w-20 {
    width: 20%;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 1px 6px;
  border: 1px solid #5c5c5c;
  background: #0569C5;
  color: white;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  .flex-grow-4 {
    flex-grow: 4;
  }
  .flex-grow-2 {
    flex-grow: 2;
  }
}

// .cs-label {
// width: 150px;
// }

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: baseline;
  input {
    transform: translateY(3px);
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  font-size: 32px !important;
}

@media print {
  body,
  html {
    height: 100vh;
    *  {
      color: black !important;
    }
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>